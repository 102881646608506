<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">支店の一括作成</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a-steps :current="current">
                <a-step v-for="item in steps" :key="item.title" :title="item.title" />
              </a-steps>
              <div style="margin-top: 40px; padding-bottom: 30px;">
                <div v-if="current === 0">
                  <p>テンプレートファイルをダウンロードし、作成したい支店をエクセルにて入力してください。作成しましたらページ下部よりファイルをアップロードしてください。</p>
                  <a-card title="支店の一括作成テンプレート" style="margin-bottom: 30px;">
                    <p class="text-center" style="font-size: 1.2em"><a href="templates/branch_template.xlsx" class="text-primary"><i class="fa fa-file-excel-o" /> テンプレートファイル</a></p>
                    <p>
                      1行目は変更しないでください。<br>
                      2行目はサンプルとしていれているので、2行目から書き換えてお使いください。<br>
                      ※ 以下項目の選択肢についてです。それぞれ数値を入力してください。<br>
                      アルコールチェック方式は [0: しない 1: ドライバーが点呼完了処理 2: 管理者が点呼完了処理]<br>
                      乗車可否デフォルト値は [0: 未選択, 1: 許可 2: 非許可]<br>
                      アルコール検知器デフォルト値は [0: 未選択, 1: 有, 2: 無]<br>
                      酒気帯びデフォルト値は [0: 未選択, 1: 有, 2: 無]<br>
                      点呼方法デフォルト値は [0: 未選択 1: 対面 2: ビデオ通話, 3: 電話 4: その他]<br>
                      運行前点検の利用の値は [0: 使用しない 1: 使用する]
                    </p>
                  </a-card>
                  <a-upload-dragger
                    name="file"
                    :multiple="false"
                    :beforeUpload="beforeUpload"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">
                      クリックしてファイルを選択 または ファイルをドラッグ してください。
                    </p>
                    <p class="ant-upload-hint">
                      ファイルはテンプレートに沿って作られたものに限ります。
                    </p>
                  </a-upload-dragger>
                </div>
                <div v-if="current === 1">
                  <h5 v-if="errorCount" class="text-danger" style="margin-bottom: 20px;">入力にエラーがあります。背景に<span class="wrongCell">色がついているセル</span>を確認してください。</h5>
                  <a-table
                    :columns="columns"
                    :data-source="excelData"
                    rowKey="メールアドレス"
                    :pagination="false"
                    style="margin-bottom: 30px;"
                  >
                    <span slot="name" slot-scope="name">{{ name ? name : '必須項目です。'}}</span>
                    <span slot="email" slot-scope="email">{{ email }}</span>
                    <span slot="alcohol_check_type" slot-scope="alcohol_check_type" >{{ alcohol_check_type == 0 ? '未選択' : alcohol_check_type == 1 ? 'ドライバーが点呼完了処理' : alcohol_check_type == 2 ? '管理者が点呼完了処理' : '0 ~ 2 を入力してください'}}</span>
                    <span slot="alcohol_check_default_allow_ride" slot-scope="alcohol_check_default_allow_ride" >{{ alcohol_check_default_allow_ride == 0 ? '未選択' : alcohol_check_default_allow_ride == 1 ? '許可' : alcohol_check_default_allow_ride == 2 ? '非許可' : '0 ~ 2 を入力してください'}}</span>
                    <span slot="alcohol_check_default_alcohol_detector" slot-scope="alcohol_check_default_alcohol_detector" >{{ alcohol_check_default_alcohol_detector == 0 ? '未選択' : alcohol_check_default_alcohol_detector == 1 ? '有' : alcohol_check_default_alcohol_detector == 2 ? '無' : '0 ~ 2 を入力してください'}}</span>
                    <span slot="alcohol_check_default_drinking" slot-scope="alcohol_check_default_drinking" >{{ alcohol_check_default_drinking == 0 ? '未選択' : alcohol_check_default_drinking == 1 ? '有' : alcohol_check_default_drinking == 2 ? '無' : '0 ~ 2 を入力してください'}}</span>
                    <span slot="alcohol_check_default_roll_call_type" slot-scope="alcohol_check_default_roll_call_type" >{{ alcohol_check_default_roll_call_type == 0 ? '未選択' : alcohol_check_default_roll_call_type == 1 ? '対面' : alcohol_check_default_roll_call_type == 2 ? 'ビデオ通話' : alcohol_check_default_roll_call_type == 3 ? '電話' : alcohol_check_default_roll_call_type == 4 ? 'その他' : '0 ~ 4 を入力してください'}}</span>
                    <span slot="use_inspection_flag" slot-scope="use_inspection_flag" >{{ use_inspection_flag == 0 ? '使用しない' : use_inspection_flag == 1 ? '使用する' : '0 ~ 1 を入力してください'}}</span>
                  </a-table>
                  <p v-if="!errorCount">以上のデータで作成してよろしいですか？</p>
                  <div class="steps-action">
                    <a-button v-if="!errorCount" type="primary" @click="createUser" :loading="createLoading">
                      作成する
                    </a-button>
                    <a-button style="margin-left: 8px" @click="prev">
                      戻る
                    </a-button>
                  </div>
                </div>
                <div v-if="current === 2">
                  <p>2番目</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import XLSX from 'xlsx'

export default {
  data() {
    return {
      current: 0,
      createLoading: false,
      steps: [
        { title: 'エクセルでデータを作成' },
        { title: 'データの確認' },
        { title: '作成' },
      ],
      columns: [
        {
          title: '支店名',
          dataIndex: '支店名',
          scopedSlots: { customRender: 'name' },
          customCell: (text, row, index) => {
            if (!this.checkName(text['支店名'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '所在地',
          dataIndex: '所在地',
          scopedSlots: { customRender: 'address' },
        },
        {
          title: '備考',
          dataIndex: '備考',
          scopedSlots: { customRender: 'comment' },
        },
        {
          title: 'アルコールチェック方式',
          dataIndex: 'アルコールチェック方式',
          scopedSlots: { customRender: 'alcohol_check_type' },
          customCell: (text, row, index) => {
            if (!this.checkAlcoholCheckType(text['アルコールチェック方式'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '乗車可否デフォルト値',
          dataIndex: '乗車可否デフォルト値',
          scopedSlots: { customRender: 'alcohol_check_default_allow_ride' },
          customCell: (text, row, index) => {
            if (!this.checkAlcoholCheckDefaultAllowRide(text['乗車可否デフォルト値'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: 'アルコール検知器デフォルト値',
          dataIndex: 'アルコール検知器デフォルト値',
          scopedSlots: { customRender: 'alcohol_check_default_alcohol_detector' },
          customCell: (text, row, index) => {
            if (!this.checkAlcoholCheckDefaultAlcoholDetector(text['アルコール検知器デフォルト値'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '酒気帯びデフォルト値',
          dataIndex: '酒気帯びデフォルト値',
          scopedSlots: { customRender: 'alcohol_check_default_drinking' },
          customCell: (text, row, index) => {
            if (!this.checkAlcoholCheckDefaultDrinking(text['酒気帯びデフォルト値'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '点呼方法デフォルト値',
          dataIndex: '点呼方法デフォルト値',
          scopedSlots: { customRender: 'alcohol_check_default_roll_call_type' },
          customCell: (text, row, index) => {
            if (!this.checkAlcoholCheckDefaultRollCallType(text['点呼方法デフォルト値'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '運行前点検の使用',
          dataIndex: '運行前点検の使用',
          scopedSlots: { customRender: 'use_inspection_flag' },
          customCell: (text, row, index) => {
            if (!this.checkUseInspectionFlag(text['運行前点検の使用'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
      ],
      excelData: null,
      errorCount: 0,
    }
  },
  watch: {
    excelData: function(val) {
      const _this = this
      for (const row in val) {
        if (!_this.checkName(val[row]['支店名'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkAlcoholCheckType(val[row]['アルコールチェック方式'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkAlcoholCheckDefaultAllowRide(val[row]['乗車可否デフォルト値'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkAlcoholCheckDefaultAlcoholDetector(val[row]['アルコール検知器デフォルト値'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkAlcoholCheckDefaultDrinking(val[row]['酒気帯びデフォルト値'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkAlcoholCheckDefaultRollCallType(val[row]['点呼方法デフォルト値'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkUseInspectionFlag(val[row]['運行前点検の使用'])) {
          _this.errorCount++
          break
        }
      }
    },
  },
  created() {},
  methods: {
    next() {
      this.current++
    },
    prev() {
      this.current--
    },
    beforeUpload(file) {
      const _this = this
      _this.errorCount = 0
      console.log(file)
      const reader = new FileReader()
      reader.onload = function(file) {
        const data = new Uint8Array(file.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        console.log(workbook)
        _this.excelData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
      }
      reader.readAsArrayBuffer(file)
      this.current++
      return false
    },
    handleChange(info) {
      const status = info.file.status
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    checkName(val) {
      if (!val || val.length > 255) {
        return false
      }
      return true
    },
    checkAlcoholCheckType(val) {
      if (val >= 0 && val <= 2) {
        return true
      }
      return false
    },
    checkAlcoholCheckDefaultAllowRide(val) {
      if (val >= 0 && val <= 2) {
        return true
      }
      return false
    },
    checkAlcoholCheckDefaultAlcoholDetector(val) {
      if (val >= 0 && val <= 2) {
        return true
      }
      return false
    },
    checkAlcoholCheckDefaultDrinking(val) {
      if (val >= 0 && val <= 2) {
        return true
      }
      return false
    },
    checkAlcoholCheckDefaultRollCallType(val) {
      if (val >= 0 && val <= 4) {
        return true
      }
      return false
    },
    checkUseInspectionFlag(val) {
      if (val >= 0 && val <= 1) {
        return true
      }
      return false
    },
    createUser() {
      this.createLoading = true
      const result = Vue.prototype.$api.send('post', 'branches/bulk', { branches: this.excelData })
      result.then(response => {
        this.$notification['success']({
          message: '支店を作成しました',
        })
        this.$router.push('/company')
        this.createLoading = false
      })
        .catch(error => {
          let duration = 4.5
          if (error.status === 500) {
            duration = 15
          }
          this.$notification['error']({
            message: error.status + ': ' + error.data.data,
            duration: duration,
          })
          this.createLoading = false
        })
    },
  },
}
</script>

<style>
.wrongCell {
  background-color: #FED1D1;
}
</style>
