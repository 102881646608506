var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              [
                _c(
                  "a-steps",
                  { attrs: { current: _vm.current } },
                  _vm._l(_vm.steps, function(item) {
                    return _c("a-step", {
                      key: item.title,
                      attrs: { title: item.title }
                    })
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "40px",
                      "padding-bottom": "30px"
                    }
                  },
                  [
                    _vm.current === 0
                      ? _c(
                          "div",
                          [
                            _c("p", [
                              _vm._v(
                                "テンプレートファイルをダウンロードし、作成したい支店をエクセルにて入力してください。作成しましたらページ下部よりファイルをアップロードしてください。"
                              )
                            ]),
                            _c(
                              "a-card",
                              {
                                staticStyle: { "margin-bottom": "30px" },
                                attrs: { title: "支店の一括作成テンプレート" }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: { "font-size": "1.2em" }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-primary",
                                        attrs: {
                                          href: "templates/branch_template.xlsx"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-file-excel-o"
                                        }),
                                        _vm._v(" テンプレートファイル")
                                      ]
                                    )
                                  ]
                                ),
                                _c("p", [
                                  _vm._v(
                                    "\n                    1行目は変更しないでください。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                    2行目はサンプルとしていれているので、2行目から書き換えてお使いください。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                    ※ 以下項目の選択肢についてです。それぞれ数値を入力してください。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                    アルコールチェック方式は [0: しない 1: ドライバーが点呼完了処理 2: 管理者が点呼完了処理]"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                    乗車可否デフォルト値は [0: 未選択, 1: 許可 2: 非許可]"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                    アルコール検知器デフォルト値は [0: 未選択, 1: 有, 2: 無]"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                    酒気帯びデフォルト値は [0: 未選択, 1: 有, 2: 無]"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                    点呼方法デフォルト値は [0: 未選択 1: 対面 2: ビデオ通話, 3: 電話 4: その他]"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                    運行前点検の利用の値は [0: 使用しない 1: 使用する]\n                  "
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "a-upload-dragger",
                              {
                                attrs: {
                                  name: "file",
                                  multiple: false,
                                  beforeUpload: _vm.beforeUpload
                                }
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "ant-upload-drag-icon" },
                                  [_c("a-icon", { attrs: { type: "inbox" } })],
                                  1
                                ),
                                _c("p", { staticClass: "ant-upload-text" }, [
                                  _vm._v(
                                    "\n                    クリックしてファイルを選択 または ファイルをドラッグ してください。\n                  "
                                  )
                                ]),
                                _c("p", { staticClass: "ant-upload-hint" }, [
                                  _vm._v(
                                    "\n                    ファイルはテンプレートに沿って作られたものに限ります。\n                  "
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.current === 1
                      ? _c(
                          "div",
                          [
                            _vm.errorCount
                              ? _c(
                                  "h5",
                                  {
                                    staticClass: "text-danger",
                                    staticStyle: { "margin-bottom": "20px" }
                                  },
                                  [
                                    _vm._v("入力にエラーがあります。背景に"),
                                    _c("span", { staticClass: "wrongCell" }, [
                                      _vm._v("色がついているセル")
                                    ]),
                                    _vm._v("を確認してください。")
                                  ]
                                )
                              : _vm._e(),
                            _c("a-table", {
                              staticStyle: { "margin-bottom": "30px" },
                              attrs: {
                                columns: _vm.columns,
                                "data-source": _vm.excelData,
                                rowKey: "メールアドレス",
                                pagination: false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "name",
                                    fn: function(name) {
                                      return _c("span", {}, [
                                        _vm._v(
                                          _vm._s(name ? name : "必須項目です。")
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "email",
                                    fn: function(email) {
                                      return _c("span", {}, [
                                        _vm._v(_vm._s(email))
                                      ])
                                    }
                                  },
                                  {
                                    key: "alcohol_check_type",
                                    fn: function(alcohol_check_type) {
                                      return _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            alcohol_check_type == 0
                                              ? "未選択"
                                              : alcohol_check_type == 1
                                              ? "ドライバーが点呼完了処理"
                                              : alcohol_check_type == 2
                                              ? "管理者が点呼完了処理"
                                              : "0 ~ 2 を入力してください"
                                          )
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "alcohol_check_default_allow_ride",
                                    fn: function(
                                      alcohol_check_default_allow_ride
                                    ) {
                                      return _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            alcohol_check_default_allow_ride ==
                                              0
                                              ? "未選択"
                                              : alcohol_check_default_allow_ride ==
                                                1
                                              ? "許可"
                                              : alcohol_check_default_allow_ride ==
                                                2
                                              ? "非許可"
                                              : "0 ~ 2 を入力してください"
                                          )
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key:
                                      "alcohol_check_default_alcohol_detector",
                                    fn: function(
                                      alcohol_check_default_alcohol_detector
                                    ) {
                                      return _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            alcohol_check_default_alcohol_detector ==
                                              0
                                              ? "未選択"
                                              : alcohol_check_default_alcohol_detector ==
                                                1
                                              ? "有"
                                              : alcohol_check_default_alcohol_detector ==
                                                2
                                              ? "無"
                                              : "0 ~ 2 を入力してください"
                                          )
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "alcohol_check_default_drinking",
                                    fn: function(
                                      alcohol_check_default_drinking
                                    ) {
                                      return _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            alcohol_check_default_drinking == 0
                                              ? "未選択"
                                              : alcohol_check_default_drinking ==
                                                1
                                              ? "有"
                                              : alcohol_check_default_drinking ==
                                                2
                                              ? "無"
                                              : "0 ~ 2 を入力してください"
                                          )
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "alcohol_check_default_roll_call_type",
                                    fn: function(
                                      alcohol_check_default_roll_call_type
                                    ) {
                                      return _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            alcohol_check_default_roll_call_type ==
                                              0
                                              ? "未選択"
                                              : alcohol_check_default_roll_call_type ==
                                                1
                                              ? "対面"
                                              : alcohol_check_default_roll_call_type ==
                                                2
                                              ? "ビデオ通話"
                                              : alcohol_check_default_roll_call_type ==
                                                3
                                              ? "電話"
                                              : alcohol_check_default_roll_call_type ==
                                                4
                                              ? "その他"
                                              : "0 ~ 4 を入力してください"
                                          )
                                        )
                                      ])
                                    }
                                  },
                                  {
                                    key: "use_inspection_flag",
                                    fn: function(use_inspection_flag) {
                                      return _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            use_inspection_flag == 0
                                              ? "使用しない"
                                              : use_inspection_flag == 1
                                              ? "使用する"
                                              : "0 ~ 1 を入力してください"
                                          )
                                        )
                                      ])
                                    }
                                  }
                                ],
                                null,
                                false,
                                897441824
                              )
                            }),
                            !_vm.errorCount
                              ? _c("p", [
                                  _vm._v(
                                    "以上のデータで作成してよろしいですか？"
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "steps-action" },
                              [
                                !_vm.errorCount
                                  ? _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          loading: _vm.createLoading
                                        },
                                        on: { click: _vm.createUser }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    作成する\n                  "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "8px" },
                                    on: { click: _vm.prev }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    戻る\n                  "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.current === 2
                      ? _c("div", [_c("p", [_vm._v("2番目")])])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "utils__title mb-3" }, [
      _c("strong", { staticClass: "text-uppercase font-size-16" }, [
        _vm._v("支店の一括作成")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }